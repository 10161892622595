import React from 'react';
import ProgressSection from '../../../components/external-digital-pod/progress-section';
// eslint-disable-next-line sonarjs/cognitive-complexity
const SubHeader = ({ selectedDPod, total_processed, isOnline }) => {
  return (
    <div style={{ width: '100%' }} className={`w-100 sub-header px-16 pb-4`}>
      <div className='d-flex align-items-center fs-10 pb-4'>
        <div
          className='rounded-circle'
          style={{
            width: '8px',
            height: '8px',
            backgroundColor: isOnline ? '#17C000' : '#C00000',
            marginRight: '8px',
          }}
        ></div>
        <span className='font-weight-medium text-dark fs-10'>
          {isOnline ? 'ONLINE' : 'OFFLINE'}
        </span>
      </div>
      <ProgressSection
        totalProcessed={selectedDPod?.stat?.total_processed || 0}
        totalRejected={selectedDPod?.stat?.total_rejected || 0}
        totalItems={selectedDPod?.stat?.total || 0}
        label='Status'
      />
    </div>
  );
};
SubHeader.propTypes = {};
export default SubHeader;
