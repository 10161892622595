import React, { Fragment, useCallback, useRef, useState } from 'react';
import { DPodShipmentProgress } from '../../views/digitalpod/digital-pod-shipments';
import { CutGuideWoCardStat } from '../../views/cutguide/common';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import {
  setCurrentScanItem,
  setRejectReasonModal,
  setSelectedPendingCard,
} from '../../store/digitalpod/dpodexternstationstate';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import useMobile from '../../hooks/useMobile';
import {
  DeliveryMethodModal,
  populateDigitalPodData,
} from '../../helpers/ext-digitalpod/digitalPodHelper';
import BottomDrawerInit from '../BottomDrawer/DrawerInit';
import InfiniteScroll from 'react-infinite-scroller';

const PendingCard = ({ pendingCardDetails, pendingCardStat }) => {
  const { org } = useSelector(state => state.user);

  return (
    <Fragment>
      <div className='d-pod-card-container'>
        <div className='px-16 py-16 rubik-font'>
          <div className='d-pod-card'>
            <span className='d-pod-card-id'>{pendingCardDetails?.code}</span>

            <div className='d-pod-card-body'>
              <div className=' location-container d-flex justify-content-between py-16'>
                <span className='location me-2'>
                  <span>Location:</span>{' '}
                  <span className='text-primary'>
                    {pendingCardDetails?.delivery_address}
                  </span>
                </span>
              </div>

              <div className='d-pod-card-info'>
                <CutGuideWoCardStat
                  statCount={pendingCardStat.stat?.box_processed}
                  totalCount={pendingCardStat.stat?.box_total}
                  label={'BOXES'}
                />
                <div className='d-flex flex-column justify-content-end'>
                  <div className='vendor-name'>{org?.name}</div>
                  <div className='date-time'>
                    {moment(pendingCardDetails?.created_on).format(
                      "Do MMM 'YY, hh:mma",
                    )}
                  </div>{' '}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='d-pod-pipeline-progress'>
          <DPodShipmentProgress shipment={pendingCardStat} />
        </div>
      </div>
    </Fragment>
  );
};

const PendingPodList = ({ dPodList }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const loadMoreTrigger = useRef(false);
  const [isMobile] = useMobile();
  const [bottomDrawerModal, setBottomDrawerModal] = useState(false);
  const [total] = useState(50);
  const [itemsPerPage] = useState(10);
  const fetchPendingPodList = useCallback(
    async page => {
      try {
        loadMoreTrigger.current = true;
        const infiniteReq = { page, limit: itemsPerPage };

        await populateDigitalPodData('', dispatch, infiniteReq);
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        loadMoreTrigger.current = false;
      }
    },
    [dispatch, itemsPerPage],
  );

  const loadMore = useCallback(
    page => {
      if (dPodList.length < total && !loadMoreTrigger.current) {
        fetchPendingPodList(page);
      }
    },
    [dPodList.length, fetchPendingPodList, total],
  );

  const ModalBody = useCallback(
    () =>
      bottomDrawerModal ? (
        <DeliveryMethodModal setBottomDrawerModal={setBottomDrawerModal} />
      ) : null,
    [bottomDrawerModal],
  );

  return (
    <div
      style={{ height: 'calc(100vh - 72px)', overflow: 'auto' }}
      className='d-pod-card-list-container'
    >
      <InfiniteScroll
        pageStart={0}
        loadMore={loadMore}
        hasMore={dPodList.length < total}
        threshold={250}
        useWindow={false}
        // loader={<div key={0}>Loading...</div>}
      >
        {dPodList?.map((list, index) => {
          const pendingDetails = list?.shipments?.[`${list.ctx_id}`];
          return (
            <div
              className='pb-2'
              key={index}
              onClick={() => {
                dispatch(setCurrentScanItem({}));
                dispatch(setRejectReasonModal(false));
                if (list?.delivery_method) {
                  history.push(`/app/dpodexternal/details/${list.dPod_id}`);
                } else {
                  setBottomDrawerModal(true);
                  dispatch(setSelectedPendingCard(list));
                }
              }}
            >
              <PendingCard
                pendingCardDetails={pendingDetails}
                pendingCardStat={list}
              />
            </div>
          );
        })}
      </InfiniteScroll>

      <BottomDrawerInit
        entity={'showMenuModal'}
        isMobile={isMobile}
        data={ModalBody}
        menuModal={bottomDrawerModal}
        setMenuModal={setBottomDrawerModal}
      />
    </div>
  );
};

export default PendingPodList;
