import React, { Fragment, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import Header from './external/header';
import ScreenLoader from '../../components/common/screen-loader';
import PropTypes from 'prop-types';

import { getOverlayStyle } from '../../helpers/common/helper';

import ScreenOverlay from '../../components/common/screen-overlay';
import Footer from './external/footer';
import { populateDigitalPodData } from '../../helpers/ext-digitalpod/digitalPodHelper';
import PendingPodList from '../../components/external-digital-pod/pending-pod';
import CompletedPodList from '../../components/external-digital-pod/completed-pod';

const DigitalPodExternalHome = () => {
  const dispatch = useDispatch();
  const [isCompletedList, setIsCompletedList] = useState(false);
  const populatingData = useRef(false);
  //eslint-disable-next-line
  const [baseStyles] = useState(getOverlayStyle());
  const { dPodExternalState } = useSelector(
    state => state.dpodexternstationstate,
  );
  const [term, SetTerm] = useState('');
  const [filteredDPodState, setFilteredDPodState] = useState([]);
  useEffect(() => {
    const filteredData = term
      ? dPodExternalState.filter(
          v =>
            v.shipments?.[v.ctx_id]?.code?.toLowerCase() === term.toLowerCase(),
        )
      : dPodExternalState;
    setFilteredDPodState(filteredData);
  }, [dPodExternalState, term]);

  useEffect(() => {
    if (populatingData.current === true) {
      return;
    }
    populateDigitalPodData(term, dispatch);
    populatingData.current = true;
  }, [dispatch, term]);

  return (
    <Fragment>
      <div
        className='height-100-percent width-100-percent'
        style={{
          height: '100%',
          overflowX: 'hidden',
          backgroundColor: '#EFEFEF',
        }}
      >
        <div style={{ display: 'block', overflow: 'hidden' }}>
          <ScreenLoader />
        </div>
        <div
          style={baseStyles.overlayStyle}
          className='scroll-content container-fluid'
        >
          <ScreenOverlay module={'dpodexternal'} />
        </div>
        <div style={baseStyles.screenStyle}>
          <div
            className='container-fluid position-relative px-0 '
            style={{
              display: 'block',
              minHeight: '100%',
              backgroundColor: '#EFEFEF',
              paddingBottom: '32px',
            }}
          >
            <Header
              isCompletedList={isCompletedList}
              setIsCompletedList={setIsCompletedList}
              onSearch={text => {
                SetTerm(text);
              }}
            />
            {dPodExternalState && (
              <div
                style={{
                  position: 'relative',
                  top: 174,
                  width: '100%',
                }}
              >
                {!isCompletedList ? (
                  <PendingPodList
                    dPodList={
                      filteredDPodState.filter(
                        v => v.dPOd_status === 'PENDING',
                      ) || []
                    }
                  />
                ) : (
                  <CompletedPodList
                    dPodList={
                      filteredDPodState.filter(
                        v => v.dPOd_status === 'COMPLETED',
                      ) || []
                    }
                  />
                )}
              </div>
            )}

            <Footer />
          </div>
        </div>
      </div>
      <ToastContainer />
    </Fragment>
  );
};

export default DigitalPodExternalHome;

DigitalPodExternalHome.propTypes = {
  location: PropTypes.object,
};
