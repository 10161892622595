import React from 'react';
import Status from '../packing/common/status';
import StatusBox from '../common/status-box';

const ProgressSection = ({
  totalProcessed,
  totalRejected,
  totalItems,
  label,
}) => {
  return (
    <div className='w-100'>
      <div className='pb-4'>
        <Status
          onClick={null}
          label={label}
          completed={totalProcessed || 0}
          total={totalItems || 0}
        />
      </div>
      <div className='w-100 d-flex gap-5 justify-content-start'>
        <div className='track-trace-status-box-wrapper'>
          <StatusBox
            onClick={null}
            count={totalProcessed || 0}
            label='PROCESSED'
            color='green'
          />
        </div>
        <div className='track-trace-status-box-wrapper'>
          <StatusBox
            onClick={null}
            count={totalRejected || 0}
            label='REJECTED'
            color='red'
          />
        </div>
      </div>
    </div>
  );
};

export default ProgressSection;
