import React, {
  Fragment,
  useState,
  useCallback,
  useEffect,
  useRef,
  useMemo,
} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCircleExclamation,
  faTriangleExclamation,
  faEllipsisV,
  faAngleLeft,
  faCamera,
  faCircleXmark,
  faImage,
} from '@fortawesome/free-solid-svg-icons';
import SwipeCTA from './swipe';
import BoxModel from './boxModel';
import SubHeader from './sub-header';
import { useDispatch, useSelector } from 'react-redux';
import {
  useHistory,
  useParams,
} from 'react-router-dom/cjs/react-router-dom.min';
import {
  setCurrentScanItem,
  setDPodExternalPendingSync,
  setDPodExternalState,
  setLastScanItem,
  setRejectMode,
  setRejectReasonModal,
  updatePendSyncStateItems,
} from '../../../store/digitalpod/dpodexternstationstate';
import BottomDrawerInit from '../../../components/BottomDrawer/DrawerInit';
import useMobile from '../../../hooks/useMobile';
import {
  DeliveryMethodModal,
  handelOnlineScan,
  ImagesUploader,
  onUndoHandler,
  resetStatusStat,
  toastSuccess,
  toastUndo,
  updateOrdersRejectedDetails,
  updatePodRejectedState,
} from '../../../helpers/ext-digitalpod/digitalPodHelper';
import { cloneDeep } from 'lodash';
import OrderCard from '../../../components/external-digital-pod/order-pod-card';
import {
  completeDPod,
  getExternalDPodScanItems,
  scanExternalDPod,
  updateDPod,
} from '../../../services/shipment';
import DigitalPodQrScanner from './qrcodeScaner';
import { toastResponseError } from '../../../helpers/common/helper';
import { toast, ToastContainer } from 'react-toastify';
import ManualEntry from './manualDelivery';
const homePage = '/app/dpodexternal';
const select_reasons = 'selectReasons';
const ShipmentDetail = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();
  const hasSubmitted = useRef(false);
  const isOnline = navigator.onLine;
  const [isMobile] = useMobile();
  const [entityModal, setEntityModal] = useState('');
  const [bottomDrawerModal, setBottomDrawerModal] = useState(false);
  const [dPodDetails, setDPodDetails] = useState({});
  console.log('🚀 > ShipmentDetail > dPodDetails:', dPodDetails);
  const {
    dPodExternalState,
    reject_mode,
    currentScanItem: scanItem,
    rejection_reasons_modal,
  } = useSelector(state => {
    return state.dpodexternstationstate;
  });
  const currentScanItem = useMemo(() => scanItem, [scanItem]);
  const [value, setValue] = useState('');
  const [selectedReasons, setSelectedReasons] = useState([]);
  const [expandedGroups, setExpandedGroups] = useState({});
  const [showQrModal, setShowQrModal] = useState(false);
  const [rejectionReasons, setRejectionReasons] = useState([]);
  const [boxSelectionFlag, setBoxSelectionFlag] = useState(false);
  const [dPodOrder, setDPodOrder] = useState([]);
  console.log('🚀 > ShipmentDetail > dPodOrder:', dPodOrder);
  const total_processed =
    (dPodDetails?.stat?.item_processed || 0) +
    (dPodDetails?.stat?.box_processed || 0);
  const shipmentCode = `Shipment ${
    dPodDetails?.shipments?.[dPodDetails?.ctx_id]?.code
  }`;
  const scanCodes = dPodDetails?.result?.map(
    item => item.box?.code || item.order_item?.code,
  );
  console.log('🚀 > ShipmentDetail > scanCodes:', scanCodes);
  console.log('🚀 > ShipmentDetail > entityModal:', entityModal);

  useEffect(() => {
    if (
      rejection_reasons_modal &&
      currentScanItem &&
      Object.keys(currentScanItem).length
    ) {
      setEntityModal(select_reasons);
      setBottomDrawerModal(true);
      const item =
        currentScanItem.type === 'BOX'
          ? currentScanItem?.box?.box_items?.[0]?.order_box_item
          : currentScanItem?.order_item;

      const rejectReasons =
        item?.item_definition?.definition?.REJECTION_REASONS?.value?.val?.map(
          group => ({
            group: group.group,
            reasons: group.reasons,
          }),
        );

      setRejectionReasons(rejectReasons || []);
      hasSubmitted.current = false;
    }
  }, [currentScanItem, entityModal, rejection_reasons_modal]);

  const submitHandler = useCallback(
    async manualEntry => {
      console.log('🚀 > ShipmentDetail > manualEntry:', manualEntry);

      try {
        const { ctx_id, shipments, org_id, dPod_id } = dPodDetails || {};
        const branch_id = shipments?.[`${ctx_id}`]?.branch_id;

        const reqPayload = {
          shipment_id: ctx_id,
          branch_id,
          status: 'PENDING',
          manual_entry: manualEntry,
        };
        console.log('🚀 > ShipmentDetail > reqPayload:', reqPayload);

        const response = await updateDPod(org_id, dPod_id, [], reqPayload);

        if (response.status === 200) {
          toastSuccess(`Order updated`, () => ({}));
        } else {
          toastResponseError('Order updating failed');
        }
        console.log('Update successful:', response);
      } catch (error) {
        console.error('Error updating dPod:', error);
      }
    },
    [dPodDetails],
  );

  const handleRejectionSubmit = useCallback(async () => {
    const rejectScanCode =
      currentScanItem?.order_item?.code || currentScanItem?.box?.code;

    let uploadStatus = false;

    try {
      if (isOnline) {
        const res = await handelOnlineScan(dPodDetails, currentScanItem);
        if (res?.status === 200) {
          uploadStatus = true;
        } else {
          toastResponseError(`${rejectScanCode} rejection failed`);
        }
      } else {
        uploadStatus = true;
        dispatch(updatePendSyncStateItems(dPodDetails, currentScanItem));
      }
    } catch (error) {
      console.log('Error during rejection submit:', error);
    } finally {
      if (uploadStatus) {
        setEntityModal('');
        const { updatedState, currentDpod, undoItem } = updatePodRejectedState(
          dPodExternalState,
          rejectScanCode,
        );
        dispatch(setLastScanItem(undoItem));
        const { updatedState: fullyUpdatedState } = updateOrdersRejectedDetails(
          updatedState,
          currentDpod,
          rejectScanCode,
        );
        dispatch(setDPodExternalState(fullyUpdatedState));
        toastSuccess(`${rejectScanCode} Damaged`, () =>
          onUndoHandler(
            fullyUpdatedState,
            currentDpod,
            undoItem,
            undefined,
            isOnline,
            dispatch,
          ),
        );
      }

      if (reject_mode) {
        dispatch(setRejectMode(false));
      }
    }
  }, [
    currentScanItem,
    isOnline,
    dPodDetails,
    dispatch,
    reject_mode,
    dPodExternalState,
  ]);
  const resetStatusHandel = useCallback(async () => {
    const res = await getExternalDPodScanItems(
      dPodDetails.org_id,
      dPodDetails.branch_id,
      dPodDetails.dPod_id,
    );
    const req_payload = res?.payload?.map(scanItem => {
      return {
        scan_code: scanItem?.scan_code,
        manual_entry: false,
        qty: 1,
        active: true,
        deleted: true,
        approved: true,
      };
    });
    if (!req_payload.length) return;
    let resStatus = false;
    try {
      const payload = {
        active: true,
        deleted: true,
        approved: true,
        req_payload,
      };
      const res = await scanExternalDPod(
        dPodDetails.org_id,
        dPodDetails.dPod_id,
        payload,
      );
      if (res.status === 200) {
        resStatus = true;
        toastUndo('Shipment Reset successfully');
      }
    } catch (err) {
      console.error('scan failed:', err);
    }
    if (resStatus || !isOnline) {
      const resetDeliveryStat = resetStatusStat(dPodExternalState, dPodDetails);
      dispatch(setDPodExternalState(resetDeliveryStat));
    }
  }, [dPodDetails, dPodExternalState, dispatch, isOnline]);

  useEffect(() => {
    if (entityModal === 'REJECTION_SUBMIT' && !hasSubmitted.current) {
      hasSubmitted.current = true;
      handleRejectionSubmit();
    }
  }, [entityModal, handleRejectionSubmit]);

  useEffect(() => {
    if (!id || dPodExternalState.length === 0) {
      setDPodDetails({});
      return;
    }
    const selectedPod = dPodExternalState.find(v => v.dPod_id === id);
    setDPodDetails(selectedPod);
  }, [id, dPodExternalState]);
  const handleSelectReasonChange = useCallback(childReason => {
    setSelectedReasons(prevSelected => {
      if (prevSelected.includes(childReason)) {
        return prevSelected.filter(item => item !== childReason);
      }
      return [...prevSelected, childReason];
    });
  }, []);
  const handleCompleteProceed = useCallback(async () => {
    setEntityModal('successDetails');
    setBottomDrawerModal(true);

    if (isOnline) {
      const res = await completeDPod(dPodDetails.org_id, dPodDetails.dPod_id);
      if (res.status === 200) {
        dispatch(
          setDPodExternalState(
            cloneDeep(dPodExternalState).map(v =>
              v.dPod_id === dPodDetails.dPod_id
                ? {
                    ...v,
                    dPOd_status: res?.payload?.status
                      ? res?.payload?.status
                      : v.dPOd_status,
                  }
                : v,
            ),
          ),
        );
      }
    } else {
      dispatch(
        setDPodExternalState(
          cloneDeep(dPodExternalState).map(v =>
            v.dPod_id === dPodDetails.dPod_id
              ? {
                  ...v,
                  pending_sync: [...(v.pending_sync || []), 'completed'],
                }
              : v,
          ),
        ),
      );
      dispatch(setDPodExternalPendingSync(true));
    }
  }, [isOnline, dPodDetails, dispatch, dPodExternalState]);

  const ModalBody = useCallback(
    type => {
      if (bottomDrawerModal) {
        switch (entityModal) {
          case 'Reset Delivery Status':
            return (
              <div style={{ height: 'auto' }} className='row my-16 naadi-text'>
                <div className='pb-4'>
                  <FontAwesomeIcon
                    icon={faCircleExclamation}
                    style={{ color: '#FFD43B', fontSize: '40px' }}
                  />
                </div>
                <p
                  style={{ fontWeight: '500', fontSize: '24px' }}
                  className=' pb-3'
                >
                  {' '}
                  Are you sure?
                </p>
                <p style={{ paddingBottom: '10px' }}>
                  If you choose to &quot; Reset Delivery Status &quot;, you will
                  lose all the progress made so far. This cannot be undone once
                  changed.
                </p>
                <p> Are you sure you want to continue?</p>
                <div
                  style={{
                    width: '100%',
                  }}
                >
                  <div>
                    <button
                      className='naadi-back-btn py-16 mb-3'
                      onClick={() => {
                        setBottomDrawerModal(false);
                        setEntityModal('');
                      }}
                    >
                      Back
                    </button>
                    <button
                      className='dpod-warning-btn py-16'
                      onClick={() => {
                        setBottomDrawerModal(false);
                        resetStatusHandel();
                      }}
                    >
                      I understand, Proceed
                    </button>
                  </div>
                </div>
              </div>
            );
          case select_reasons:
            return (
              <div className='w-100 px-16 my-16 fontRubik'>
                <div className='d-flex fontRubik align-items-center justify-content-between pb-3'>
                  <div style={{ fontWeight: '500', fontSize: '24px' }}>
                    Select a reason
                  </div>
                  <div>
                    <FontAwesomeIcon
                      icon={faCamera}
                      size='2xl'
                      style={{ color: '#006EED' }}
                    />
                  </div>
                </div>

                <div className='ps-2 '>
                  {rejectionReasons?.map((reasonGroup, index) => (
                    <div key={reasonGroup.group} className='mb-3'>
                      <div className='d-flex input '>
                        <input
                          type='checkbox'
                          className='main-box-checkbox cursor-pointer'
                          id={`group-${index}`}
                          value={reasonGroup.group}
                          checked={!!expandedGroups[reasonGroup.group]}
                          onChange={() => {
                            setExpandedGroups(prevState => ({
                              ...prevState,
                              [reasonGroup.group]:
                                !prevState[`${reasonGroup.group}`],
                            }));
                          }}
                        />
                        <label
                          className='ps-3 my-auto naadi-text'
                          htmlFor={`group-${index}`}
                        >
                          {reasonGroup.group}
                        </label>
                      </div>

                      {expandedGroups[reasonGroup.group] && (
                        <div className='ms-4 mt-3'>
                          {reasonGroup.reasons.map((childReason, idx) => (
                            <div key={idx} className='d-flex input mt-3'>
                              <input
                                type='checkbox'
                                className='main-box-checkbox cursor-pointer'
                                id={`reason-${index}-${idx}`}
                                value={childReason}
                                checked={selectedReasons.includes(childReason)}
                                onChange={() =>
                                  handleSelectReasonChange(childReason)
                                }
                              />
                              <label
                                className='ps-3 my-auto naadi-text'
                                htmlFor={`reason-${index}-${idx}`}
                              >
                                {childReason}
                              </label>
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                  ))}
                </div>

                <div className='py-2 px-2 rubik-font bg-light'>
                  <span style={{ fontSize: '10px' }}>FIELD NAME</span>
                  <div className='d-flex justify-content-between'>
                    <input
                      type='text'
                      className='form-control border-0 input-custom py-1'
                      id='value'
                      placeholder='Enter Value Here'
                      value={value}
                      onChange={e => setValue(e.target.value)}
                    />
                    <FontAwesomeIcon
                      icon={faCircleXmark}
                      style={{ color: '#32323266', paddingLeft: '10px' }}
                      onClick={() => setValue('')}
                    />
                  </div>
                </div>

                <div
                  className='d-flex justify-content-between w-100 pt-5 p-0 m-0'
                  style={{ gap: '1rem' }}
                >
                  <button
                    className='naadi-back-btn py-16'
                    onClick={() => {
                      dispatch(setRejectReasonModal(false));
                      setShowQrModal(true);
                    }}
                  >
                    Back
                  </button>
                  <button
                    className='naadi-save-btn py-16 text-white'
                    onClick={() => {
                      const scanItem = cloneDeep(currentScanItem);
                      if (currentScanItem.order_item) {
                        scanItem.order_item.reasons = selectedReasons;
                        scanItem.order_item.value = value;
                      } else if (scanItem.box) {
                        scanItem.box.reasons = selectedReasons;
                        scanItem.box.value = value;
                      }
                      dispatch(setCurrentScanItem(scanItem));
                      dispatch(setRejectReasonModal(false));
                      setEntityModal('uploadImagesToComplete');
                      setSelectedReasons([]);
                      setValue('');

                      setExpandedGroups({});
                    }}
                  >
                    Proceed
                  </button>
                </div>
              </div>
            );
          case 'Change Delivery Type':
            return (
              <div style={{ height: 'auto' }} className='row my-16 naadi-text'>
                <div className='pb-4'>
                  <FontAwesomeIcon
                    icon={faCircleExclamation}
                    style={{ color: '#FFD43B', fontSize: '40px' }}
                  />
                </div>
                <p
                  style={{ fontWeight: '500', fontSize: '24px' }}
                  className='fontRubik pb-3'
                >
                  {' '}
                  You will lose progress
                </p>
                <p style={{ paddingBottom: '10px' }}>
                  If you choose to change the delivery type now, you will lose
                  all progress.
                </p>
                <p> Are you sure you want to continue?</p>
                <div
                  style={{
                    width: '100%',
                  }}
                >
                  <div>
                    <button
                      className='naadi-back-btn py-16 mb-3'
                      onClick={() => {
                        setBottomDrawerModal(false);
                        setEntityModal('');
                      }}
                    >
                      Back
                    </button>
                    <button
                      className='dpod-warning-btn py-16'
                      onClick={() => {
                        setEntityModal('deliveryMethodModal');
                      }}
                    >
                      I understand, Proceed
                    </button>
                  </div>
                </div>
              </div>
            );
          case 'itemsAreNotDelivered':
            return (
              <div style={{ height: 'auto' }} className='row my-16 naadi-text'>
                <div className='pb-4'>
                  <FontAwesomeIcon
                    icon={faCircleExclamation}
                    style={{ color: '#FFD43B', fontSize: '40px' }}
                  />
                </div>
                <p
                  style={{ fontWeight: '500', fontSize: '24px' }}
                  className='fontRubik'
                >
                  {' '}
                  All items are not delivered
                </p>
                <p style={{ paddingBottom: '10px' }}>
                  Are you sure you want to mark the order as complete, as all
                  items are not addressed yet in the order?
                </p>
                <p> Are you sure you want to continue?</p>
                <div
                  style={{
                    width: '100%',
                  }}
                >
                  <div>
                    <button
                      className='naadi-back-btn py-16 mb-3'
                      onClick={() => {
                        setBottomDrawerModal(false);
                        setEntityModal('');
                      }}
                    >
                      Back
                    </button>
                    <button
                      className='dpod-warning-btn py-16'
                      onClick={() => {
                        setEntityModal('uploadImagesToComplete');
                      }}
                    >
                      Confirm & Continue
                    </button>
                  </div>
                </div>
              </div>
            );
          case 'addImageModal':
            return (
              <ImagesUploader
                dPodDetails={dPodDetails}
                setEntityModal={setEntityModal}
                setBottomDrawerModal={setBottomDrawerModal}
              />
            );
          case 'confirmTheDetails':
            return (
              <div className=' bg-white px-16 position-fixed top-0 start-0 w-100 h-100 py-16 naadi-text'>
                <div className='d-flex flex-row text-center gap-3 mt-5 mb-3 text-center'>
                  <FontAwesomeIcon
                    icon={faAngleLeft}
                    style={{ color: '#030303', fontSize: '16px' }}
                  />
                  <p style={{ fontSize: '20px' }}>Review</p>
                </div>
                <div className='mb-1 mt-3'>
                  <FontAwesomeIcon
                    icon={faCircleExclamation}
                    style={{ color: '#006EED', fontSize: '40px' }}
                  />
                </div>
                <div className=' d-flex flex-column gap-2 mt-4'>
                  <div
                    style={{ fontWeight: '500', fontSize: '24px' }}
                    className='fontRubik'
                  >
                    Confirm the details{' '}
                  </div>
                  <div className='fs-6'>To mark the order as complete</div>
                </div>
                <div
                  className='w-100'
                  style={{ height: '156px', width: '328px' }}
                >
                  <div className='detail-container  my-4 py-1 px-16'>
                    <div className='pt-2 pb-3'>
                      <div className='status'>
                        <span className='fs-6'>Total Items</span>
                        <span
                          className='bottom-dashed-line'
                          style={{ width: '52vw' }}
                        ></span>
                        <span>
                          {dPodDetails?.stat?.box_total +
                            dPodDetails?.stat?.item_total}
                        </span>
                      </div>
                      <div className='status'>
                        <span className='fs-6'>Delivered</span>
                        <span
                          className='bottom-dashed-line'
                          style={{ width: '204vw' }}
                        ></span>
                        <span>{total_processed}</span>
                      </div>
                      <div className='status'>
                        <span className='fs-6'>Damaged</span>
                        <span
                          className='bottom-dashed-line'
                          style={{ width: '200vw' }}
                        ></span>
                        <span>0</span>
                      </div>
                      <div className='status mb-0 '>
                        <span className='fs-6'>Missing</span>
                        <span
                          className='bottom-dashed-line'
                          style={{ width: '210vw' }}
                        ></span>
                        <span>
                          {dPodDetails?.stat?.box_total +
                            dPodDetails?.stat?.item_total -
                            total_processed}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='d-flex flex-row justify-content-between py-2 mt-4'
                  style={{ height: '32px' }}
                >
                  <div style={{ fontSize: '14px' }}>
                    Attach image
                    <span className=''>* </span>
                  </div>
                  <div
                    className='rounded-1 d-flex flex-row gap-1 justify-content-center align-items-center'
                    style={{
                      width: '107px',
                      backgroundColor: '#027AFF1A',
                      height: '32px',
                    }}
                  >
                    <div style={{ fontSize: '14px', color: '#006EED' }}>
                      Image (5)
                    </div>
                    <FontAwesomeIcon
                      icon={faImage}
                      style={{ color: '#006EED', fontSize: '12px' }}
                    />
                  </div>
                </div>
                <div className='mt-4'>Add Note</div>
                <div className='note-container mt-4'>
                  <label className='notes-label' htmlFor='notes'>
                    NOTES
                  </label>
                  <div className='notes-box mt-1' id='notes'>
                    You may add any note here
                  </div>
                </div>
                <div className='position-fixed bottom-0'>
                  <div className='d-flex flex-row justify-content-between padding-btn'>
                    <button
                      className='naadi-back-btn py-16 mb-3'
                      onClick={() => {
                        setEntityModal('');
                      }}
                    >
                      Back
                    </button>
                    <button
                      className='naadi-save-btn py-16'
                      onClick={handleCompleteProceed}
                    >
                      Proceed
                    </button>
                  </div>
                </div>
              </div>
            );
          case 'successDetails':
            return (
              <div className=' bg-white px-16 position-fixed top-0 start-0 w-100 h-100 py-16 naadi-text'>
                <div className='d-flex flex-row text-center gap-3 mt-5 mb-3 text-center'>
                  <FontAwesomeIcon
                    icon={faAngleLeft}
                    style={{ color: '#030303', fontSize: '16px' }}
                  />
                  <p style={{ fontSize: '20px' }}>Review</p>
                </div>
                <div className='mb-1 mt-3'>
                  <FontAwesomeIcon
                    icon={faCircleExclamation}
                    style={{ color: '#006EED', fontSize: '40px' }}
                  />
                </div>
                <div className=' d-flex flex-column gap-2 mt-4'>
                  <div
                    style={{ fontWeight: '500', fontSize: '24px' }}
                    className='fontRubik'
                  >
                    Confirm the details{' '}
                  </div>
                  <div className='fs-6'>To mark the order as complete</div>
                </div>
                <div
                  className='w-100'
                  style={{ height: '156px', width: '328px' }}
                >
                  <div className='detail-container  my-4 py-1 px-16'>
                    <div className='pt-2 pb-3'>
                      <div className='status'>
                        <span className='fs-6'>Total Items</span>
                        <span
                          className='bottom-dashed-line'
                          style={{ width: '52vw' }}
                        ></span>
                        <span>60</span>
                      </div>
                      <div className='status'>
                        <span className='fs-6'>Delivered</span>
                        <span
                          className='bottom-dashed-line'
                          style={{ width: '204vw' }}
                        ></span>
                        <span>50</span>
                      </div>
                      <div className='status'>
                        <span className='fs-6'>Damaged</span>
                        <span
                          className='bottom-dashed-line'
                          style={{ width: '200vw' }}
                        ></span>
                        <span>3</span>
                      </div>
                      <div className='status mb-0 '>
                        <span className='fs-6'>Missing</span>
                        <span
                          className='bottom-dashed-line'
                          style={{ width: '210vw' }}
                        ></span>
                        <span>7</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='d-flex flex-row justify-content-between py-2 mt-4'
                  style={{ height: '32px' }}
                >
                  <div style={{ fontSize: '14px' }}>
                    Attach image
                    <span className=''>* </span>
                  </div>
                  <div
                    className='rounded-1 d-flex flex-row gap-1 justify-content-center align-items-center'
                    style={{
                      width: '107px',
                      backgroundColor: '#027AFF1A',
                      height: '32px',
                    }}
                  >
                    <div style={{ fontSize: '14px', color: '#006EED' }}>
                      Image (5)
                    </div>
                    <FontAwesomeIcon
                      icon={faImage}
                      style={{ color: '#006EED', fontSize: '12px' }}
                    />
                  </div>
                </div>
                <div className='mt-4'>Add Note</div>
                <div className='note-container mt-4'>
                  <label className='notes-label' htmlFor='notes'>
                    NOTES
                  </label>
                  <div className='notes-box mt-1' id='notes'>
                    You may add any note here
                  </div>
                </div>
                <div className='position-fixed bottom-0'>
                  <div className='d-flex flex-row justify-content-between padding-btn'>
                    <button
                      className='naadi-back-btn'
                      style={{ width: '159px', height: '48px' }}
                      onClick={() => {
                        setEntityModal('');
                      }}
                    >
                      Back
                    </button>
                    <button
                      style={{ width: '159px', height: '48px' }}
                      className='naadi-save-btn'
                      onClick={() => {
                        setEntityModal('successDetails');
                      }}
                    >
                      Proceed
                    </button>
                  </div>
                </div>
              </div>
            );
          case 'deliveryMethodModal':
            return (
              <DeliveryMethodModal
                setBottomDrawerModal={setBottomDrawerModal}
                resetDeliveryType={true}
              />
            );
          case 'uploadImagesToComplete':
            return (
              <div style={{ height: 'auto' }} className='row my-16 naadi-text'>
                <div className='pb-3'>
                  <FontAwesomeIcon
                    icon={faCamera}
                    size='2xl'
                    style={{ color: '#006EED' }}
                  />
                </div>
                <p
                  style={{ fontWeight: '500', fontSize: '24px' }}
                  className='fontRubik pb-3'
                >
                  Upload images to complete{' '}
                </p>
                <p style={{ paddingBottom: '10px' }}>
                  To mark the order as {reject_mode ? 'reject' : 'complete'} you
                  need to upload atleast one image
                </p>
                <div
                  style={{
                    width: '100%',
                  }}
                >
                  <div>
                    <button
                      className='naadi-back-btn py-16 mb-3'
                      onClick={() => {
                        if (reject_mode) {
                          setEntityModal(select_reasons);
                        } else {
                          setBottomDrawerModal(false);
                          setEntityModal('');
                        }
                      }}
                    >
                      Back
                    </button>
                    <button
                      className='naadi-save-btn py-16'
                      onClick={() => {
                        setEntityModal('addImageModal');
                      }}
                    >
                      Proceed
                    </button>
                  </div>
                </div>
              </div>
            );
          case 'optionsMenuModal':
            return (
              <div className='row col-lg-12 col-md-9 my-16'>
                <div className='col-12 flex-column rubik-font'>
                  <p
                    style={{
                      textAlign: 'center',
                      fontSize: '20px',
                      marginBottom: '15px',
                      fontWeight: 400,
                    }}
                  >
                    {shipmentCode}
                  </p>
                  <div
                    style={{
                      textAlign: 'center',
                      fontSize: '16px',
                      color: '#006eed',
                      fontWeight: 400,
                    }}
                    className='mt-5'
                  >
                    <div
                      onClick={() => {
                        setBottomDrawerModal(true);
                        setEntityModal('Change Delivery Type');
                      }}
                    >
                      Change Delivery Type{' '}
                    </div>
                    <hr style={{ backgroundColor: 'black' }} />
                    <div
                      onClick={() => {
                        setBottomDrawerModal(true);
                        setEntityModal('Reset Delivery Status');
                      }}
                    >
                      Reset Delivery Status{' '}
                    </div>
                    <hr style={{ backgroundColor: 'black' }} />
                    <div onClick={() => {}}>Report an issue </div>
                  </div>
                </div>
              </div>
            );
          case 'manual_entry_for_delivery':
            return (
              <ManualEntry
                order={dPodOrder}
                onCancel={() => setBottomDrawerModal(false)}
                submitHandler={submitHandler}
              />
            );
          default:
            return 'Wrong Choice!';
        }
      }
    },
    [
      bottomDrawerModal,
      currentScanItem,
      dPodDetails,
      dPodOrder,
      dispatch,
      entityModal,
      expandedGroups,
      handleCompleteProceed,
      handleSelectReasonChange,
      reject_mode,
      rejectionReasons,
      resetStatusHandel,
      selectedReasons,
      shipmentCode,
      submitHandler,
      total_processed,
      value,
    ],
  );

  return (
    <Fragment>
      <div
        style={{
          position: 'fixed',
          top: 0,
          left: 0,
          background: 'white',
          zIndex: 1,
          width: '100%',
        }}
      >
        <div className='d-flex bg-white align-items-center justify-content-between py-16 px-16'>
          <div
            onClick={() => {
              history.push(homePage);
            }}
            className='d-flex align-items-center '
          >
            <div className='pe-2'>
              <FontAwesomeIcon icon={faAngleLeft} />
            </div>
            <div className='title-text rubik-font fs-5'>{shipmentCode}</div>
          </div>
          <span
            onClick={() => {
              setEntityModal('optionsMenuModal');
              setBottomDrawerModal(true);
            }}
          >
            <FontAwesomeIcon icon={faEllipsisV} />
          </span>
        </div>
        <SubHeader
          selectedDPod={dPodDetails}
          total_processed={total_processed}
          isOnline={isOnline}
        />
      </div>
      <div
        style={{ position: 'relative', top: 230 }}
        className='d-pod-card-list-container mx-16 my-16'
      >
        {dPodDetails?.ordersDetails?.map((order, index) => {
          return (
            <Fragment key={index}>
              <div
                onClick={() => {
                  if (dPodDetails.delivery_method === 'enter_number_of_boxes') {
                    setDPodOrder(order.result);
                    setEntityModal('manual_entry_for_delivery');
                    setBottomDrawerModal(true);
                  } else {
                    setBoxSelectionFlag(true);
                  }
                }}
              >
                <OrderCard order={order} />
              </div>
            </Fragment>
          );
        })}
      </div>
      {boxSelectionFlag && (
        <BoxModel onBack={() => setBoxSelectionFlag(false)} />
      )}
      <div
        style={{
          position: 'fixed',
          bottom: 0,
          left: 0,
          width: '100%',
        }}
      >
        <div className='d-flex gap-1 py-8 px-32 justify-content-center align-items-center'>
          <button
            onClick={() => {
              setShowQrModal(true);
              dispatch(setRejectMode(true));
            }}
            style={{
              width: '48px',
              height: '48px',
              borderRadius: '60%',
              backgroundColor: '#C00000',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              border: 'none',
            }}
            className='ext-dpod-shadow'
          >
            <FontAwesomeIcon
              icon={faTriangleExclamation}
              size='lg'
              style={{ color: 'white' }}
            />
          </button>
          <button
            onClick={() => {
              if (reject_mode) {
                dispatch(setRejectMode(false));
              }
              setShowQrModal(true);
            }}
            style={{
              width: 'fit-content',
              height: '48px',
              backgroundColor: '#006EED',
              color: 'white',
              border: 'none',
              borderRadius: '40px',
              padding: '0px 20px',
            }}
            className='ext-dpod-shadow'
          >
            Scan Delivery
          </button>
        </div>
        <div className='bg-white py-16 border-top ext-dpod-shadow'>
          <SwipeCTA
            total_processed={total_processed}
            total_items={
              dPodDetails?.stat?.box_total + dPodDetails?.stat?.item_total
            }
            setEntityModal={setEntityModal}
            setBottomDrawerModal={setBottomDrawerModal}
          />
        </div>
      </div>
      {showQrModal && (
        <DigitalPodQrScanner
          showModal={showQrModal}
          onCloseCb={() => {
            setShowQrModal(false);
          }}
          dPodDetails={dPodDetails}
        />
      )}

      <BottomDrawerInit
        entity={'showMenuModal'}
        isMobile={isMobile}
        data={ModalBody}
        menuModal={bottomDrawerModal}
        setMenuModal={setBottomDrawerModal}
      />
      <ToastContainer
        position={toast.POSITION.BOTTOM_CENTER}
        className=' custom-toast-container-dpod naadi-text fs-2'
      />
    </Fragment>
  );
};

export default ShipmentDetail;
